<template>
  <div style="min-height: 90vh">
    <h1>No Page</h1>
  </div>
</template>

<script>


export default {

}
</script>
